import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const SectionHeader = ({ heading, description }) => (
  <Fragment>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-12">
            <div className="tile">
              <h3 id={heading.replace(/ /g,"-")} className="section-title is-size-2">{heading}</h3>
            </div>
            <div className="tile">
              <p className="theme-p has-text-justified">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Fragment>
);

SectionHeader.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
}

export default SectionHeader
