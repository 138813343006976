import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


const ContentCard = ({ heading, description, image, link }) => (
  <Fragment>
    <div className="column is-4">
      <div className="card what-we-do">
        <div class="card-image card-content">
          <figure class="image">
            <PreviewCompatibleImage imageInfo={image} />
          </figure>
        </div>
        <div className="card-content" style={{ minHeight: '100px' }}>
          <p className="title card-title has-text-centered">
            {heading}
          </p>
        </div>
        <div className="card-content" style={{ minHeight: '275px' }}>
          <div className="content has-text-justified">
            {description}
          </div>
        </div>
        {link && (
          <footer className="card-footer">
            <p class="card-footer-item">
              <Link to={link}>
                Keep Reading →
              </Link>
            </p>
          </footer>
        )}
      </div>
    </div>
  </Fragment>
)

ContentCard.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.string,
}

export default ContentCard
