import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SectionHeader from './SectionHeader';
import ContentCard from './ContentCard';


const ServiceGrid = ({ categories }) => categories.map(category => (
  <Fragment>
    <SectionHeader heading={category.heading} description={category.description} />
    <div key={category.heading} className="column is-12">
      <div className="columns is-multiline">
        {category.services.map(service => (
          <ContentCard
            key={service.heading}
            image={service}
            heading={service.heading}
            description={service.description}
          />
        ))}
      </div>
    </div>
  </Fragment>
))

ServiceGrid.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      services: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        })
      ),
    })
  ),
}

export default ServiceGrid
